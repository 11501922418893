import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import { useToast } from "../../components/Toast/toastHook";
import ListingForm from "../../components/listings/ListingForm";
import { GET_COMPANY_USERS } from "../../graphql/companyQueries";
import {
  GET_COMPANY_LISTINGS,
  GET_LISTING_BY_ID,
  UPDATE_LISTING,
} from "../../graphql/listingQueries";
import { useListings } from "../../context/ListingContext";
import dayjs from "dayjs";

const UpdateListing = () => {
  const { companyId, listingId } = useParams();
  const navigate = useNavigate();

  const [agents, setAgents] = useState([]);

  const [initialValues, setInitialValues] = useState({
    streetAddr: "",
    city: "",
    state: "",
    zip: "",
    beds: "",
    baths: "",
    yearBuilt: "",
    price: "",
    sqFt: "",
    lotSize: parseInt(),
    acreage: parseInt(),
    notes: "",
    expiration: new Date(),
    buyer_commission: "",
    company: companyId,
    user: "",
    office: "",
    imageURLs: [],
    amenities: [],
    links: [],
  });

  const {
    isOpenHouse,
    setOpenHouses,
    setIsOpenHouse,
    getFormattedOpenHouses,
    resetOpenHouses,
  } = useListings();

  const {
    loading: listingLoad,
    data: listingData,
    error: listingError,
  } = useQuery(GET_LISTING_BY_ID, {
    variables: {
      listingId,
    },
    fetchPolicy: "no-cache",
    onError(e) {
      toast("error", "There was an error loading the listing data");
    },
  });

  const {
    loading: agentLoad,
    data: agentData,
    error: agentError,
  } = useQuery(GET_COMPANY_USERS, {
    variables: {
      companyId,
    },
    // fetchPolicy: "no-cache",
  });

  const toast = useToast(4000);
  const formikRef = useRef();

  const [updateListing, { loading, data, error }] = useMutation(
    UPDATE_LISTING,
    {
      refetchQueries: [
        { query: GET_COMPANY_LISTINGS, variables: { companyId } },
      ],
      onCompleted() {
        resetOpenHouses();
        toast("success", "Listing successfully updated!");
        navigate(`/company/${companyId}/listings`);
      },
      onError(e) {
        toast("error", e.message);
      },
    }
  );

  const handleSave = ({
    user,
    expiration,
    streetAddr,
    city,
    state,
    zip,
    beds,
    baths,
    price,
    yearBuilt,
    sqFt,
    lotSize,
    buyer_commission,
    acreage,
    notes,
    imageURLs,
    amenities,
    propertyType,
    links
  }) => {
    let agent = agents.find((a) => a.id === user);

    updateListing({
      variables: {
        listingInput: {
          _id: listingId,
          user,
          expiration,
          streetAddr,
          city,
          state,
          zip,
          beds,
          baths,
          price,
          sqFt,
          notes,
          imageURLs,
          buyer_commission,
          amenities,
          propertyType,
          lotSize: parseInt(lotSize),
          acreage: parseInt(acreage),
          yearBuilt: parseInt(yearBuilt),
          office: agent.defaultOffice._id,
          company: companyId,
          openHouses: isOpenHouse ? getFormattedOpenHouses() : [],
          links
        },
      },
    });
  };

  useEffect(() => {
    if (agentData) {
      let filteredAgents = agentData.getCompanyUsers.filter(
        (a) =>
          ["AGENT", "ADMIN 1"].includes(a.role.name) && a.deletedAt === null
      );
      setAgents(filteredAgents);
    }
  }, [agentData]);

  useEffect(() => {
    if (listingData) {
      if (listingData.getListingById?.openHouses?.length) {
        setOpenHouses(
          listingData.getListingById?.openHouses.map((oh) => ({
            startTime: oh.startTime,
            endTime: oh.endTime,
            startTimeDayJS: dayjs(oh.startTime),
            endTimeDayJS: dayjs(oh.endTime),
            needHost: oh.needHost,
          }))
        );
        setIsOpenHouse(true);
      }

      const date = new Date(parseInt(listingData.getListingById.expiration));

      setInitialValues({
        ...initialValues,
        ...listingData.getListingById,
        amenities: listingData.getListingById.amenities.map((a) => a._id),
        expiration: date.toISOString().split("T")[0],
        user: listingData.getListingById.user.id,
      });
    }
  }, [listingData]);

  if (agentLoad || listingLoad) return <Spinner />;

  return (
    <div id="Listing">
      <Breadcrumb pageName="Update a Listing" />
      <div className="py-6">
        <Card>
          <ListingForm
            initialValues={initialValues}
            handleSave={handleSave}
            type="edit"
            agents={agents}
            formikRef={formikRef}
            companyId={companyId}
            loading={loading}
          />
        </Card>
      </div>
    </div>
  );
};

export default UpdateListing;
