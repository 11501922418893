import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import Modal from "../../components/modal/Modal";
import AddAgentForm from "../../components/directory/addAgentForm";
import CompanyDirectoryEditView from "../../components/companies/CompanyDirectoryEditView";
import CompanyDirectoryDeleteView from "../../components/companies/CompanyDirectoryDeleteView";
import Breadcrumb from "../../components/Breadcrumb";
import { GET_AGENTS } from "../../graphql/directoryQueries";
import { GET_CONTACTS_BY_SUBCOMPANY } from '../../graphql/contactQuries'
import { useQuery } from "@apollo/client";
import { BsFiletypeCsv } from "react-icons/bs";
import { useToast } from "../../components/Toast/toastHook";
import { Button } from "antd";
import {
  PlusOutlined as PlusIcon,
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@ant-design/icons";

const CompanyView = ({
  companyId,
  handleBackClick,
  companyName,
  details,
  directoryName,
  subCompanyId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [formType, setFormType] = useState("add");
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentId, setAgentId] = useState(null);
  
  const [temp, setTemp] = useState("")

  const [getDetails, setGetDetails] = useState([]);

  const toast = useToast(4000);

  const { data: getContactsBySubCompany , refetch: refetchAgentData } = useQuery(GET_CONTACTS_BY_SUBCOMPANY, {
    variables: { subCompanyId },
    fetchPolicy: "no-cache",
    onCompleted(result) {
      // console.log(result);
      // console.log("result");
      // setGetDetails(result?.getDirectoryList?.[0]?.details);
    },
  });

  useEffect(() => {
    if(getContactsBySubCompany?.getContactsBySubCompany){
      setTemp(getContactsBySubCompany?.getContactsBySubCompany?.name      )
      setGetDetails(getContactsBySubCompany?.getContactsBySubCompany?.contacts);
    }
  }, [getContactsBySubCompany]);

  const handleEditModalRender = (agentToEdit) => {
    setFormType("edit");
    setSelectedAgent(agentToEdit);
    setAgentId(agentToEdit._id);
    setShowEditModal(true);
  };

  const handleDeleteModalRender = (id) => {
    setAgentId(id);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    refetchAgentData({ companyId });
  };

  const COLUMN_MAPPINGS = {
    name: "Contact Name",
    position: "Title",
    address: "Address",
    email: "Email",
    phone: "Primary Contact Number",
    url: "Website",
  };

  const handleExportAllColumns = () => {
    exportContacts(Object.keys(COLUMN_MAPPINGS));
  };

  const exportContacts = (checkedColumns) => {
    if (getDetails.length === 0) {
      toast("error", "No contacts exists.");
      return;
    }

    try {
      const contactsCSVData = [];
      getDetails.forEach((details) => {
        const columnsData = {};
        for (const column of checkedColumns) {
          switch (column) {
            case "name":
              columnsData[COLUMN_MAPPINGS[column]] = details[column];
              break;
            case "position":
              columnsData[COLUMN_MAPPINGS[column]] = details[column];
              break;
            case "address":
              columnsData[COLUMN_MAPPINGS[column]] = details[column];
              break;
            case "email":
              columnsData[COLUMN_MAPPINGS[column]] = details[column];
              break;
            case "phone":
              columnsData[COLUMN_MAPPINGS[column]] = details[column];
              break;
            case "url":
              columnsData[COLUMN_MAPPINGS[column]] = details[column];
              break;
            default:
              break;
          }
        }
        contactsCSVData.push(columnsData);
      });

      const csvData = contactsCSVData.map((user) =>
        Object.values(user)
          .map((value) => JSON.stringify(value))
          .join(",")
      );

      const csvHeaders = Object.keys(contactsCSVData[0]);
      csvData.unshift(csvHeaders.join(","));

      const csvContent = csvData.join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "contacts_data.csv";

      a.click();

      window.URL.revokeObjectURL(url);

      toast("success", "Contacts data exported successfully.");
    } catch (error) {
      console.log("error in exportContacts", error.message);
      toast("error", "There was an error in exporting contacts.");
    }
  };

  return (
    <>
      <div
        onClick={handleBackClick}
        className="w-full cursor-pointer flex items-center flex-row gap-4"
      >
        <IoIosArrowRoundBack color="#212636" size={30} />
        <p className="font-normal text-[20px] text-[#212636]">Back</p>
      </div>
      <div className="w-full pt-10 flex flex-col ">
        <Breadcrumb
          pageName="Service -"
          span={companyName + " -"}
          company={temp}
        />
        <div className="mb-4 flex justify-between items-center flex-wrap gap-6">
          <div className="flex justify-start items-center flex-wrap gap-6">
            <div className="font-medium text-black">Export Contacts:</div>
            <div>
              <BsFiletypeCsv
                size={40}
                className="hover:cursor-pointer hover:text-blue"
                onClick={handleExportAllColumns}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-x-10 border border-gray-200 p-4 rounded-2xl shadow-sm">
          <div className="h-20 flex items-center justify-end w-full px-10">
            <button
              onClick={() => {
                setFormType("add")
                setShowModal(true)
              }}
              className="bg-[#B4C540] h-[36px] px-5 text-center rounded-xl text-[#FFFFFF]"
            >
              + Add Contact
            </button>
          </div>
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-4 px-4 font-medium text-black">
                  Contact Name
                </th>
                <th className="py-4 px-4 font-medium text-black">Address</th>
                <th className="py-4 px-4 font-medium text-black">
                  Phone Number
                </th>
                <th className="py-4 px-4 font-medium text-black">Website</th>
                <th className="py-4 px-4 font-medium text-black">Actions</th>
              </tr>
            </thead>
            <tbody>
              {getDetails?.length > 0 ? (
                getDetails?.map((user, index) => (
                  <tr key={index} className="border-b border-[#eee]">
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.name || "No Name"}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.address || "No Address"}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.phone || "No Phone Number"}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.url || "No Website"}
                    </td>
                    <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                      <div className="flex items-center space-x-2">
                        <Button
                          className="border-primary text-primary"
                          icon={<EditIcon />}
                          onClick={() =>  handleEditModalRender(user)}
                        />
                        <Button
                          danger
                          icon={<DeleteIcon />}
                          onClick={() => handleDeleteModalRender(user._id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-4 px-4 text-center">
                    No contacts added yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Modal
          title={"Add Contacts Information"}
          showModal={showModal}
          setShowModal={(value) => {
            setShowModal(value);
          }}
        >
          <AddAgentForm
            companyId={companyId}
            handleClose={handleClose}
            onDirectoryListAdded={() => {}}
            formType={formType}
          />
        </Modal>
        <Modal
          title={"Edit Contacts Information"}
          showModal={showEditModal}
          setShowModal={(value) => {
            setShowEditModal(value);
          }}
        >
          <CompanyDirectoryEditView
            companyId={companyId}
            agentId={agentId}
            initialValues={selectedAgent}
            refetchData={() => {}}
            handleClose={() => {
              refetchAgentData({ companyId });
              setShowEditModal(false);
            }}
          />
        </Modal>

        <Modal
          title={"Delete Contact"}
          showModal={showDeleteModal}
          setShowModal={(value) => {
            setShowDeleteModal(value);
          }}
        >
          <CompanyDirectoryDeleteView
            setShowDeleteModal={setShowDeleteModal}
            agentId={agentId}
            refetchData={() => {}}
            companyId={companyId}
            handleClose={() => {
              refetchAgentData({ companyId });
              setShowDeleteModal(false);
            }}
          />
        </Modal>
      </div>
    </>
  );
};
export default CompanyView;
