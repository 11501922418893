import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import { useToast } from "../../components/Toast/toastHook";
import ListingForm from "../../components/listings/ListingForm";
import { useListings } from "../../context/ListingContext";
import { GET_COMPANY_USERS } from "../../graphql/companyQueries";
import {
  ADD_LISTING,
  GET_COMPANY_LISTINGS,
} from "../../graphql/listingQueries";

const Listing = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const [agents, setAgents] = useState([]);

  const [initialValues] = useState({
    streetAddr: "",
    city: "",
    state: "",
    zip: "",
    beds: 0,
    baths: "0",
    yearBuilt: parseInt(),
    price: 0,
    sqFt: 0,
    lotSize: parseInt(),
    acreage: parseInt(),
    notes: "",
    buyer_commission: "",
    expiration: new Date(),
    company: companyId,
    user: "",
    office: "",
    imageURLs: [],
    amenities: [],
    propertyType: "",
    links: []
  });

  const { isOpenHouse, getFormattedOpenHouses, resetOpenHouses } =
    useListings();

  const {
    loading: agentLoad,
    data: agentData,
    error: agentError,
  } = useQuery(GET_COMPANY_USERS, {
    variables: {
      companyId,
      sorted: true,
    },
    fetchPolicy: "no-cache",
  });

  const toast = useToast(4000);
  const formikRef = useRef();

  const [addListing, { loading, data, error }] = useMutation(ADD_LISTING, {
    refetchQueries: [{ query: GET_COMPANY_LISTINGS, variables: { companyId } }],
    onCompleted() {
      resetOpenHouses();
      formikRef.current?.resetForm();
      toast("success", "Success! You have successfully added a new listing");
      navigate(`/company/${companyId}/listings`);
    },
    onError(e) {
      console.log(e.message);
      toast("error", e.message);
    },
  });

  const handleSave = (values) => {
    let agent = agents.find((a) => a.id === values.user);

    addListing({
      variables: {
        listingInput: {
          ...values,
          office: agent.defaultOffice?._id,
          yearBuilt: parseInt(values.yearBuilt),
          lotSize: parseInt(values.lotSize),
          acreage: parseInt(values.acreage),
          openHouses: isOpenHouse ? getFormattedOpenHouses() : [],
        },
      },
    });
  };

  useEffect(() => {
    if (agentData) {
      let filteredAgents = agentData.getCompanyUsers.filter(
        (a) =>
          ["AGENT", "ADMIN 1"].includes(a.role.name) && a.deletedAt === null
      );
      setAgents(filteredAgents);
    }
  }, [agentData]);

  if (agentLoad) return <Spinner />;

  return (
    <div id="Listing">
      <Breadcrumb pageName="Add a Listing" />
      <div className="py-6">
        <Card>
          <ListingForm
            initialValues={initialValues}
            handleSave={handleSave}
            type="add"
            agents={agents}
            formikRef={formikRef}
            companyId={companyId}
            loading={loading}
          />
        </Card>
      </div>
    </div>
  );
};

export default Listing;
