import { Link, useNavigate } from "react-router-dom";
import fyrstup_icon from "../assets/new_icon.png";
import ImageKeys from "../assets/keydropoffjpg.jpg";
import { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { LOGIN_USER } from "../graphql/authQueries";
import { useMutation } from "@apollo/client";
import { useToast } from "../components/Toast/toastHook";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Login = () => {
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });
  //   const [loginError, setLoginError] = useState(null);
  const [visible, setVisible] = useState(false);

  const { login } = useContext(AuthContext);

  const navigate = useNavigate();

  const toast = useToast(5000);

  const [handleLogin, { data, loading, error }] = useMutation(LOGIN_USER, {
    async onCompleted(result) {
      if (result.login?.firstLogin) {
        // Pass state values to /first-time page
        navigate("/first-time", {
          state: {
            email: result.login.email,
            firstName: result.login.firstName,
            lastName: result.login.lastName,
            id: result.login.id
          }
        });
      } 
      else if (result.login?.role?.name === "AGENT") navigate("/404");
      else {
        await login(result.login);
        let path =
          result.login.role?.name == "SUPER_ADMIN"
            ? "/administrator/dashboard"
            : `/company/${result.login.company?._id}/dashboard`;
        navigate(path);
      }
    },
    onError(e) {
      console.error(e);
      toast(
        "error",
        // "An issue occurred! Please check your credentials and try again"
        e.message
      );
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!initialValues.email || !initialValues.password) return;

    try {
      return await handleLogin({
        variables: {
          email: initialValues.email,
          password: initialValues.password,
        },
      });
    } catch (e) {
      toast("error", error.message);
    }
  };

  return (
    <div
      className="flex items-center"
      style={{ minHeight: "calc(100vh - 90px)" }}
    >
      <div className="rounded-sm border border-stroke bg-white shadow-lg w-full md:w-3/4  mx-auto">
        <div className="flex flex-wrap items center">
          <div className="hidden w-full xl:block xl:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full "
              src={ImageKeys}
              alt="Office"
            />
          </div>
          <div className="w-full border-stroke xl:w-1/2">
            <div className="w-full p-4 sm:p-12 xl:p-17">
              <h1 className="mb-4 text-xl font-semibold text-gray-700 text-center">
                Welcome to FyrstUp! Login to get started
              </h1>
              <img
                src={fyrstup_icon}
                alt=""
                className="mx-auto h-40"
              />
              <form
                action="submit"
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="mb-2 block font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    value={initialValues.email}
                    onChange={(e) =>
                      setInitialValues({
                        ...initialValues,
                        email: e.target.value,
                      })
                    }
                    type="email"
                    placeholder="user@mail.com"
                    className="w-full rounded-md border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="mb-2 block font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      value={initialValues.password}
                      onChange={(e) =>
                        setInitialValues({
                          ...initialValues,
                          password: e.target.value,
                        })
                      }
                      type={visible ? "text" : "password"}
                      placeholder="********"
                      className="w-full rounded-md border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none"
                    />
                    <div
                      className="hover:cursor-pointer absolute right-4 top-1/3"
                      onClick={() => setVisible((prev) => !prev)}
                    >
                      {visible ? (
                        <FaEyeSlash
                          color="grey"
                          size={20}
                        />
                      ) : (
                        <FaEye
                          color="grey"
                          size={20}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-5">
                  <button
                    className="w-full flex justify-center rounded-lg border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90 disabled:bg-gray-400 disabled:border-none"
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="animate-spin">
                        <AiOutlineLoading3Quarters size={22} />
                      </div>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>

              <Link
                to="/forgot-password"
                className="text-sm font-medium text-primary hover:underline"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
