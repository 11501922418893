import { useMutation } from "@apollo/client";
import { useRef } from "react";
import { Formik, FieldArray } from "formik";
import Input from "../Input";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { EDIT_AGENT } from "../../graphql/directoryQueries";
import {UPDATE_CONTACTS} from "../../graphql/contactQuries"
import { useToast } from "../Toast/toastHook";
import AutoCompleteAddressOneLine from '../AutoCompleteAddressOneLine'
import * as Yup from 'yup';

export const agentValidationSchema = Yup.object().shape({
  details: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .required("Name is required")
            .min(2, "Name must be at least 2 characters"),
          position: Yup.string()
            .required("Title is required")
            .min(2, "Title must be at least 2 characters"),
          address: Yup.string()
            .required("Office address is required")
            .min(5, "Address must be at least 5 characters"),
          email: Yup.string()
            .required("Email is required")
            .email("Invalid email address"),
          phone: Yup.string()
              .matches(/^\d{9,15}$/, "Phone number must be between 9 and 15 digits"),
          url: Yup
          .string()
          .matches(
            /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/,
            "Please enter a valid URL."
          )
          .required("URL is required.")
        })
      )
      .min(1, "At least one agent is required")
      .required("Agent information is required"),
});

const CompanyDirectoryEditView = ({
  companyId,
  agentId,
  handleClose,
  refetchData,
  initialValues,
}) => {
  const [updateContact, { loading: editLoading }] = useMutation(UPDATE_CONTACTS, {
    onCompleted(result) {
      toast("success", `Successfully saved contact.`);
      formikRef.current?.resetForm();
      handleClose();
    },
    onError(e) {
      toast("error", e.message);
    },
  });

  const toast = useToast(3000);
  const formikRef = useRef();

  const transformedInitialValues = {
    details: [initialValues],
  };

  const handleSave = (values, { resetForm }) => {
    const { details } = values;

    const {_id, name, position, address,email, phone, url } = details[0]
    console.log(details[0])
    updateContact({
      variables: {
        contectInput: {
          _id,
          name,
          position,
          address,
          email,
          phone,
          url
        }
      }
    })
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={transformedInitialValues}
      enableReinitialize={true}
      validationSchema={agentValidationSchema}
      onSubmit={async (values, { resetForm }) => {
        handleSave(values, { resetForm });
      }}
    >
      {({ values, errors, isSubmitting, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6">
            <FieldArray name="details">
              {({ push }) => (
                <>
                  {values?.details?.map((detail, index) => (
                    <div key={index} className="mt[-30px]">
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Name"}
                          name={`details[${index}].name`}
                          error={errors.details?.[index]?.name}
                          value={detail.name}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Name"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Title"}
                          name={`details[${index}].position`}
                          error={errors.details?.[index]?.position}
                          value={detail.position}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Title"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        {/* <Input
                          label={"Office Address"}
                          name={`details[${index}].address`}
                          error={errors.details?.[index]?.address}
                          value={detail.address}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Office Address"}
                        /> */}

                         <AutoCompleteAddressOneLine
                          value={values.details[index].address}
                          setFieldValue={(value) => setFieldValue(`details[${index}].address`, value)}
                          errors={errors.details?.[index]?.address}
                        />

                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Email Address"}
                          name={`details[${index}].email`}
                          error={errors.details?.[index]?.email}
                          value={detail.email}
                          onChange={handleChange}
                          type="email"
                          placeholder={"Email Address"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Primary Contact Number"}
                          name={`details[${index}].phone`}
                          error={errors.details?.[index]?.phone}
                          value={detail.phone}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Primary Contact Number"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Website"}
                          name={`details[${index}].url`}
                          error={errors.details?.[index]?.url}
                          value={detail.url}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Website"}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              disabled={editLoading}
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting || editLoading}
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              {editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CompanyDirectoryEditView;
