import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import TableHeader from "../../components/companies/TableHeader";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_COMPANY, GET_COMPANIES, UPDATE_COMPANY } from "../../graphql/companyQueries";
import Spinner from "../../components/Spinner";
import { useToast } from "../../components/Toast/toastHook";
import { addYearToDate } from "../../utils/dateFormat";
import { GET_ALL_OPEN_HOUSES_LISTINGS } from "../../graphql/listingQueries";
import { BsFiletypeCsv } from "react-icons/bs";
import ToggleButton from "../../components/ToggleButton";

const Companies = () => {
  // const { loading, error, data } = useQuery(GET_COMPANIES);
  const { loading, error, data } = useQuery(GET_COMPANIES, {
    fetchPolicy: "no-cache",
  });
  
  const {
    loading: listingsLoading,
    error: listingsError,
    data: listingsData,
  } = useQuery(GET_ALL_OPEN_HOUSES_LISTINGS, {
    fetchPolicy: "no-cache",
  });
  const [companies, setCompanies] = useState([]);
  const [listings, setListings] = useState([]);
  const toast = useToast(3000);
  const navigate = useNavigate();
  
  const [deleteCompany, { loading: delLoading, error: delError }] = useMutation(
    DELETE_COMPANY,
    {
      refetchQueries: [
        {
          query: GET_COMPANIES,
        },
      ],
      onCompleted(result) {
        toast(
          "success",
          `You have successfully deleted ${result.deleteCompany.companyName}`
        );
      },
    }
  );

  const [updateCompany, { loading: saveLoad, error: saveError }] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [{ query: GET_COMPANIES }],
    onCompleted(res) {
      toast(
        "success",
        `${res?.updateCompany?.companyName}  ${res?.updateCompany?.isActive ? 'Activated' : 'Inactivated'} successfully.`
      );
      navigate("/administrator/companies");
    },
    onError(e) {
      console.log(e)
      toast("error", `Error! ${e.message}`);
    },
  })

  const handleDelete = (companyId) => {
    if (
      window.confirm(
        "Warning! Are you sure you would like to delete this company? This action cannot be undone"
      )
    ) {
      deleteCompany({ variables: { companyId } });
    }
    return;
  };

  const COLUMN_MAPPINGS = {
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    email: "Email",
    companyName: "Company Name",
    officeName: "Office Name",
    streetAddr: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip",
    propertyType: "Property Type",
    price: "Price",
    sqFt: "Square Feet",
    baths: "Baths",
    beds: "Beds",
    yearBuilt: "Year Built",
    expiration: "Expiration",
    createdAt: "Created At",
    openHouses: "Open Houses",
  };

  const handleExportAllColumns = () => {
    exportOpenHousesListings(Object.keys(COLUMN_MAPPINGS));
  };

  const handleCompanyStatusChange = (companyId, isActive) => {
    updateCompany({
      variables: {
        companyInput: {
          _id: companyId,
          isActive
        },
      },
    });
  }

  const exportOpenHousesListings = (checkedColumns) => {
    if (listings.length === 0) {
      toast("error", "No open houses listings exists.");
      return;
    }

    try {
      const openHousesListingsCSVData = [];

      listings.forEach((listing) => {
        const columnsData = {};

        for (const column of checkedColumns) {
          if (column === "companyName") {
            columnsData[COLUMN_MAPPINGS[column]] =
              listing.company.companyName ?? "";
            continue;
          }

          if (column === "officeName") {
            columnsData[COLUMN_MAPPINGS[column]] = listing.office.name ?? "";
            continue;
          }

          if (
            column === "firstName" ||
            column === "lastName" ||
            column === "phone" ||
            column === "email"
          ) {
            columnsData[COLUMN_MAPPINGS[column]] = listing.user[column] ?? "";
            continue;
          }

          if (column === "expiration" || column === "createdAt") {
            const date = new Date(parseInt(listing[column], 10));
            const formatted = date.toLocaleDateString("en-US", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            });

            columnsData[COLUMN_MAPPINGS[column]] =
              formatted === "Invalid Date" ? "" : formatted;
            continue;
          }

          if (column === "openHouses") {
            const openHousesList = listing.openHouses
              .map(
                (oh) =>
                  `(Start: ${new Date(
                    oh.startTime
                  ).toLocaleString()} - End: ${new Date(
                    oh.endTime
                  ).toLocaleString()} - Need Host: ${oh.needHost})`
              )
              .join(", ");
            columnsData[COLUMN_MAPPINGS[column]] = openHousesList;
            continue;
          }

          columnsData[COLUMN_MAPPINGS[column]] = listing[column] ?? "";
        }

        openHousesListingsCSVData.push(columnsData);
      });

      const csvData = openHousesListingsCSVData.map((listing) =>
        Object.values(listing)
          .map((value) => JSON.stringify(value))
          .join(",")
      );

      const csvHeaders = Object.keys(openHousesListingsCSVData[0]);
      csvData.unshift(csvHeaders.join(","));

      const csvContent = csvData.join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "open_houses_listings.csv";

      a.click();

      window.URL.revokeObjectURL(url);

      toast("success", "Open houses listings exported successfully.");
    } catch (error) {
      console.log("error in exportOpenHousesListings", error.message);
      toast("error", "There was an error in exporting open houses listings.");
    }
  };

  useEffect(() => {
    if (data) setCompanies(data.getAllCompanies);
  }, [data]);

  useEffect(() => {
    if (!listingsData?.getAllOpenHousesListings) return;
    setListings(listingsData?.getAllOpenHousesListings);
  }, [listingsData]);

  useEffect(() => {
    if (!error) return;
    toast("error", error.message);
  });

  if (loading) return <Spinner />;
  return (
    <div id="companies">
      <Breadcrumb pageName="Companies" />
      <div className="pb-6">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-medium text-black">
            Export Open Houses Listings:
          </div>
          <BsFiletypeCsv
            size={40}
            className="hover:cursor-pointer hover:text-blue"
            onClick={handleExportAllColumns}
          />
        </div>
        <Card>
          <TableHeader />
          {/* Table Headings*/}
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left grid grid-cols-7">
                <th className="col-span-2 py-4 px-4 font-medium text-black xl:pl-11">
                  Name
                </th>
                <th className="col-span-1  py-4 px-4 font-medium text-black ">
                  Active Listings
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Agents/Seats
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  End Date
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Status
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {companies.length > 0 ? (
                companies.map((c, i) => {
                  return (
                    <tr className="grid grid-cols-7" key={i}>
                      <td className="col-span-2 border-b border-[#eee] py-5 px-4 pl-9 xl:pl-11">
                        <Link
                          className="font-medium text-blue hover:underline"
                          to={`/company/${c._id}/dashboard`}
                        >
                          {c.companyName}
                        </Link>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">
                          {c.activeListingsCount}
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">{`${c.activeUsersCount}/${c.userLimit}`}</div>
                      </td>

                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">
                          {addYearToDate(c.createdAt)}
                        </div>
                      </td>
                      
                      {/* Company Status */}
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <ToggleButton status={c.isActive} statusHandler={handleCompanyStatusChange} id={c._id} />
                      </td>
                      
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="flex items-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() =>
                              navigate(`/administrator/edit-company/${c._id}`)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                          </button>
                          <button
                            className="hover:text-primary"
                            onClick={() => handleDelete(c._id)}
                          >
                            <svg
                              className="fill-current"
                              width="20"
                              height="20"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                fill=""
                              />
                              <path
                                d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                fill=""
                              />
                              <path
                                d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                fill=""
                              />
                              <path
                                d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                fill=""
                              />
                            </svg>
                          </button>
                        </div>
                      </td>

                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="flex justify-center items-center">No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

export default Companies;
