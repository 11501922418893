import { gql } from "@apollo/client";

export const GET_COMPANIES = gql`
  query {
    getAllCompanies {
      _id
      companyName
      phone
      userLimit
      addLink
      trial
      trialEnd
      onBoardingPassword
      offices {
        name
        address
        city
        state
        zip
        phone
        isDefault
      }
      activeListingsCount
      activeUsersCount
      createdAt
      isActive
    }
  }
`;

export const GET_COMPANIES_LISTING = gql`
  query {
    getAllCompanyList {
      _id
      companyName
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompanyById($companyId: String!) {
    getCompanyById(companyId: $companyId) {
      _id
      companyName
      phone
      userLimit
      addLink
      trial
      trialEnd
      onBoardingPassword
      offices {
        name
        address
        city
        state
        zip
        phone
        isDefault
      }
      users {
        firstName
        lastName
        email
        phone
        role {
          name
        }
      }
      numUsers
      createdAt
    }
  }
`;

export const GET_COMPANY_DETAILS = gql`
  query ($companyId: String!) {
    getCompanyById(companyId: $companyId) {
      _id
      companyName
      createdAt
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  query ($companyId: String!, $sorted: Boolean) {
    getCompanyUsers(companyId: $companyId, sorted: $sorted) {
      id
      firstName
      lastName
      email
      createdAt
      phone
      licenseNo
      deviceToken
      deletedAt
      isPasswordReseted
      defaultOffice {
        name
        _id
      }
      role {
        name
        _id
      }
      fullName
      lastLogin
      isActive
    }
  }
`;

export const GET_COMPANY_OFFICES = gql`
  query ($companyId: String!) {
    getCompanyOffice(companyId: $companyId) {
      _id
      name
      address
      city
      state
      zip
      state
      phone
      isDefault
      subscribedOffices
      listing_extension_period
    }
  }
`;

export const ADD_COMPANY = gql`
  mutation ($companyInput: CompanyInput) {
    addCompany(companyInput: $companyInput) {
      _id
      companyName
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation ($companyId: String) {
    deleteCompany(companyId: $companyId) {
      _id
      companyName
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation ($companyInput: CompanyInput) {
    updateCompany(companyInput: $companyInput) {
      _id
      companyName
      isActive
    }
  }
`;

export const GET_SUPER_DASH_DETAILS = gql`
  query {
    getSuperUserDashboardDetails {
      totalActiveUsers
      totalAccountsAssigned
      percentAccountsActivated
      totalActiveListings
      totalListings
      agentsEnteringListing
      agentsEnteringBuyers
      totalBuyersEntered
      totalBuyerMatches
      listingsEntered {
        month
        listingsCreated
      }
      buyersMatches {
        month
        buyersMatched
      }
    }
  }
`;

export const GET_COMPANY_DASH_DETAILS = gql`
  query getCompanyDashboardDetails($companyId: String, $currentYear: Int) {
    getCompanyDashboardDetails(
      companyId: $companyId
      currentYear: $currentYear
    ) {
      totalListings
      totalActiveListings
      companyUserLimit
      accountsAssigned
      accountsActivated
      percentAccountsActivated
      agentsEnteringListings
      agentsEnteringBuyers
      totalBuyersEntered
      totalBuyersMatched
      listingsEntered {
        month
        listingsCreated
      }
      buyersMatches {
        month
        buyersMatched
      }
    }
  }
`;

export const GET_COMPANY_AMENITIES = gql`
  query getCompanyAmenities($companyId: String!) {
    getCompanyAmenities(companyId: $companyId) {
      _id
      title
      isActive
    }
  }
`;

export const ADD_AMENITY = gql`
  mutation ($amenityInput: AmenityInput) {
    addAmenity(amenityInput: $amenityInput) {
      _id
      title
    }
  }
`;

export const UPDATE_AMENITY = gql`
  mutation ($amenityInput: AmenityInput) {
    updateAmenity(amenityInput: $amenityInput) {
      _id
      title
    }
  }
`;

export const EXPORT_CSV_CONTENT = gql`
  mutation ($exportInput: ExportInput) {
    exportCSVContent(exportInput: $exportInput) {
      csvContent
    }
  }
`;
