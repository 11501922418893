import axios from "axios";

const IMAGE_UPLOAD_API = process.env.REACT_APP_IMAGE_UPLOAD;

const IMAGE_UPLOAD_HEADERS = {
  headers: { "Content-Type": "multipart/form-data" },
};

export async function uploadImages(files) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i]);
  }

  const response = await axios.post(
    IMAGE_UPLOAD_API,
    formData,
    IMAGE_UPLOAD_HEADERS
  );

  return response.data;
}
