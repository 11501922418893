import { useMutation } from "@apollo/client";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useToast } from "../../components/Toast/toastHook";
import CompanyForm from "../../components/administrator/CompanyForm";
import { ADD_COMPANY, GET_COMPANIES } from "../../graphql/companyQueries";

const AddCompany = () => {
  const navigate = useNavigate();
  const [initialValues, seInitialValues] = useState({
    companyName: "",
    userLimit: "",
    onBoardingPassword: "",
    officeName: "",
    officeAddress: "",
    officeCity: "",
    officeState: "",
    officeZip: "",
    officePhone: "",
    officeIsDefault: true,
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userPhone: "",
  });
  const formikRef = useRef();

  const toast = useToast(4000);

  const [addCompany, { data, loading, error }] = useMutation(ADD_COMPANY, {
    refetchQueries: [{ query: GET_COMPANIES }],
    onCompleted(result) {
      toast("success", `Successfully saved ${result.addCompany?.companyName}!`);
      formikRef.current?.resetForm();
      navigate("/administrator/companies");
    },
    onError(e) {
      toast("error", `Error! ${e.message}`);
    },
  });

  const handleSave = (values, reset) => {
    const {
      companyName,
      userLimit,
      onBoardingPassword,
      officeName,
      officeAddress,
      officeCity,
      officeState,
      officeZip,
      officePhone,
      officeIsDefault,
      userFirstName,
      userLastName,
      userEmail,
      userPhone,  
    } = values

    const payload = {
      companyName,
      userLimit,
      onBoardingPassword,
      office: {
        name: officeName,
        address: officeAddress,
        city: officeCity,
        state: officeState,
        zip: officeZip,
        phone: officePhone,
        isDefault: officeIsDefault,
      },
      user: {
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail,
        phone: userPhone, 
      }
    }
    addCompany({
      variables: { companyInput: payload },
    });
  };

  return (
    <div id="add-company">
      <Breadcrumb pageName="Add a Company" />
      <CompanyForm
        initialValues={initialValues}
        handleSave={handleSave}
        formikRef={formikRef}
        type="add"
        loading={loading}
      />
    </div>
  );
};

export default AddCompany;
