import React, { useState } from "react";
import CompanyServiceListing from "../../components/CompaniesServices/CompanyServiceListing";
import { useParams } from "react-router-dom";

const Directories = () => {
  const { companyId } = useParams();

  return (
    <div>
      <CompanyServiceListing companyId={companyId} />
    </div>
  );
};

export default Directories;
