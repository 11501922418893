import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import Card from "../Card";
import Spinner from "../Spinner";
import { useToast } from "../Toast/toastHook";
import Modal from "../modal/Modal";
import { GET_ALL_SERVICES, DELETE_SERVICE } from '../../graphql/serviceQueries'
import ServiceDeleteView from "../directory/ServiceDeleteView";
import { formatDate } from "../../utils/dateFormat";
import CompanyDirectoryForm from "./CompanyServiceForm";
import ServiceEditView from "../directory/ServiceEditView";
import { Button } from "antd";
import {
  PlusOutlined as PlusIcon,
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@ant-design/icons";

const CompanyDirectoriesListing = ({ companyId }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteListModal, setShowDeleteListModal] = useState(false);
  const [formType, setFormType] = useState("add");
  const [selectedService, setSelectedService] = useState(null);
  // For edit
  const [service, setService] = useState(null);
  const [serviceTitle, setServiceTitle] = useState(null);

  const [listings, setListings] = useState([]);

  const navigate = useNavigate();

  const toast = useToast(4000);

  // const { data, loading, error, refetch } = useQuery(GET_DIRECTORIES, {
  //   fetchPolicy: "no-cache",
  // });

  const {
    data: services,
    loading: isPending,
    error: isError,
    refetch: refetchService,
  } = useQuery(GET_ALL_SERVICES, {
    fetchPolicy: "no-cache",
    variables: { companyId },
  });

  //*******add directory in company flow start****************** */
  const [deleteService] = useMutation(DELETE_SERVICE);

  // const [deleteDirectoryList] = useMutation(DELETE_DIRECTORY_LIST);

  // const handleAddCompanyInDirectory = async () => {
  //   const variables = {
  //     directoryListInput: {
  //       directory_id: selectedOption,
  //       company_id: companyId,
  //     },
  //   };
  //   for (const item of directoryData?.getDirectoryByCompany) {
  //     if (item?.directory?._id == selectedOption) {
  //       toast("error", `Directory already added in company!`);
  //       return;
  //     }
  //   }
  //   try {
  //     const response = await addDirectoryList({ variables });

  //     // Refetch data after successful mutation
  //     refetchDirectory();
  //   } catch (e) {
  //     console.error("Error while adding company:", e);
  //   }
  // };

  //*************add directory in company flow end************ */

  // useEffect(() => {
  //   if (!data?.getDirectory) return;
  //   setListings(data?.getDirectory);
  // }, [data]);

  // useEffect(() => {
  //   if (!error) return;
  //   toast("error", error.message);
  // });

  const handleEdit = (serviceForEdit) => {
    setService(serviceForEdit._id);
    setServiceTitle(serviceForEdit.title);
    setFormType("edit");
    setShowEditModal(true);
  };

  const handleDelete = async (serviceId) => {
    try {
     const { data } = await deleteService({
        variables: { serviceId },
      });
      refetchService();
      toast("success", `${data.deleteService.title} service deleted successfully.`);
    } catch (error) {
      toast("error", error.message);
    }
  };

  const handleModalRender = () => {
    setFormType("add");
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteModalRender = (listingId) => {
    setSelectedService(listingId);
    setShowDeleteModal(true);
  };

  const [selectedOption, setSelectedOption] = useState("");

  // Handle option selection
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // if (loading) return <Spinner />;
  // console.log("selectedOption", selectedOption);
  // console.log("data from listing state", listings);

  return (
    <div>

     <div>
        <div>
          <div className="h-20 flex items-center justify-end w-full px-10">
            <button
              className="bg-[#B4C540] h-[36px] px-5 text-center rounded-xl text-[#FFFFFF]"
              onClick={handleModalRender}
            >
              + Add New Service
            </button>
          </div>
          
          {/* <div className="w-full max-w-xs mx-auto mt-5">
            <p className="block text-sm font-medium text-gray-700 mb-2">
            Select a Service
            </p>
            <div className="relative">
              <select
                id="directory"
                value={selectedOption}
                onChange={handleOptionChange}
                className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500"
              >
                <option value="">Select a Service</option>
                {listings &&
                  listings.map((item) => (
                    <option key={item.id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
              </select>
            

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="h-4 w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M5.23 7.21a.75.75 0 011.06.02L10 10.98l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.25a.75.75 0 01-1.08 0L5.23 8.25a.75.75 0 01.02-1.06z" />
                </svg>
              </div>
            </div>
          </div> */}

          
          {/* <button
            // onClick={() =>
            //   navigate(`/company/${companyId}/directories/${selectedOption}`)
            // }

            onClick={handleAddCompanyInDirectory}
            className="flex w-full max-w-xs mx-auto mt-5 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
          >
            Continue
          </button> */}

        </div>

        {/* <Modal
          title={"Add Other Directory"}
          showModal={showModal}
          setShowModal={(value) => {
            setShowModal(value);
          }}
        >
          <CompanyDirectoryForm
            handleClose={handleClose}
            onDirectoryAdded={() => refetch()}
            formType={formType}
            directory={service}
            companyId={companyId}
            clickedFrom={"company"}
          />
        </Modal> */}
      </div> 
        
      <div id="companies">
        <Breadcrumb pageName="Services" />
        <div className="pb-6">
          <Card>
            {/* Table Headings*/}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-100 text-left grid grid-cols-5">
                  <th className="col-span-2 py-4 px-4 font-medium text-black xl:pl-11">
                    Name
                  </th>
                  <th className="col-span-1  py-4 px-4 font-medium text-black ">
                    Date Created
                  </th>
                  <th className="col-span-1 py-4 px-4 font-medium text-black">
                    Date Updated
                  </th>
                  <th className="col-span-1 py-4 px-4 font-medium text-black">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {services?.getAllServices?.length > 0 ? (
                  services?.getAllServices?.map((d, i) => {
                    return (
                      <tr className="grid grid-cols-5" key={i}>
                        <td
                          className="col-span-2 border-b border-[#eee] py-4 px-4 pl-9 xl:pl-11 cursor-pointer"
                        >
                          <Link to={`/company/${companyId}/services/${d._id}`}>
                          <div className="text-black flex flex-row items-center space-x-4">
                            <div>{d?.title}</div>
                          </div>
                          </Link>
                          
                        </td>
                        <td className="col-span-1 border-b border-[#eee] py-4 px-4">
                          <div className="font-medium text-black">
                            {formatDate(d?.createdAt)}
                          </div>
                        </td>
                        <td className="col-span-1 border-b border-[#eee] py-4 px-4">
                          <div className="font-medium text-black">
                            {formatDate(d?.updatedAt)}
                          </div>
                        </td>
                        <td className="col-span-1 border-b border-[#eee] py-4 px-4">
                        <div className="flex items-center space-x-2">
                        <Link
                            to={`/company/${companyId}/services/${d._id}?name=${d.title}`}
                          >
                            <Button
                              className="border-secondary text-secondary"
                              icon={<PlusIcon />}
                            />
                          </Link>
                          
                          <Button
                            className="border-primary text-primary"
                            icon={<EditIcon />}
                            onClick={() => handleEdit(d)}
                          />
                          <Button
                            danger
                            icon={<DeleteIcon />}
                            onClick={() => handleDelete(d._id)}
                          />
                        </div>
                        </td>

                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center py-5">
                      No services found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
        </div>
        <Modal
          title={"Add New Service"}
          showModal={showModal}
          setShowModal={(value) => {
            setShowModal(value);
          }}
        >
          <CompanyDirectoryForm
            handleClose={handleClose}
            onDirectoryAdded={() => {
              refetchService();
              // refetch();
            }}
            formType={formType}
            companyId={companyId}
            directory={service}
            clickedFrom={"company"}
          />
        </Modal>
        <Modal
          title={"Edit Service"}
          showModal={showEditModal}
          setShowModal={(value) => {
            setShowEditModal(value);
          }}
        >
          <ServiceEditView
            setShowEditModal={setShowEditModal}
            serviceId={service}
            currentTitle={serviceTitle}
            handleClose={() => {
              setShowEditModal(false);
              refetchService();
            }}
            clickedFrom={"company"}
          />
        </Modal>
        <Modal
          title={"Delete Service"}
          showModal={showDeleteModal}
          setShowModal={(value) => {
            setShowDeleteModal(value);
          }}
        >
          <ServiceDeleteView
            setShowDeleteModal={setShowDeleteModal}
            directorytId={selectedService}
            handleClose={() => {
              setShowDeleteModal(false);
              // refetch();
            }}
          />
        </Modal>
        {/* <Modal
          title="Assigned Agents"
          showModal={agentsModal}
          setShowModal={(value) => {
            setAgentsModal(value);
            // setAgents([]);
          }}
        ></Modal> */}
      </div>
    </div>
  );
};

export default CompanyDirectoriesListing;
