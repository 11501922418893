import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import Spinner from "../../components/Spinner";
import { useToast } from "../../components/Toast/toastHook";
import Modal from "../../components/modal/Modal";
import ServiceForm from "../../components/directory/ServiceForm";
import ServiceDeleteView from "../../components/directory/ServiceDeleteView";
import { formatDate } from "../../utils/dateFormat";
import { GET_ALL_SERVICES } from '../../graphql/serviceQueries'
import { Button } from "antd";
import {
  PlusOutlined as PlusIcon,
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
} from "@ant-design/icons";

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formType, setFormType] = useState("add");
  const [selectedService, setSelectedService] = useState(null);
  // For edit
  const [service, setService] = useState(null);

  const [serviceListing, setServiceListing] = useState([]);

  const toast = useToast(4000);

  const { data, loading, error, refetch } = useQuery(GET_ALL_SERVICES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!data?.getAllServices) return;
    setServiceListing(data?.getAllServices);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    toast("error", error.message);
  });

  const handleEdit = (directoryToEdit) => {
    setService(directoryToEdit);
    setFormType("edit");
    setShowModal(true);
  };
  const handleModalRender = () => {
    setFormType("add");
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteModalRender = (serviceId) => {
    setSelectedService(serviceId);
    setShowDeleteModal(true);
  };

  return (
    <div id="companies">
      <Breadcrumb pageName="Services" />
      <div className="pb-6">
        <Card>
          {/* Table header */}
          <div className="h-20 flex items-center justify-end w-full px-10">
            <button
              className="bg-[#B4C540] h-[36px] px-5 text-center rounded-xl text-[#FFFFFF]"
              onClick={handleModalRender}
            >
              + Add service
            </button>
          </div>
          {/* Table Headings*/}
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left grid grid-cols-5">
                <th className="col-span-2 py-4 px-4 font-medium text-black xl:pl-11">
                  Types
                </th>
                <th className="col-span-1  py-4 px-4 font-medium text-black ">
                  Date Created
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Date Updated
                </th>
                {/* <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Live Date
                </th> */}
                <th className="col-span-1 py-4 px-4 font-medium text-black">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceListing?.length > 0 ? (
                serviceListing.map((d, i) => {
                  return (
                    <tr className="grid grid-cols-5" key={i}>
                      <td className="col-span-2 border-b border-[#eee] py-5 px-4 pl-9 xl:pl-11">
                        <Link
                          to={`/administrator/services/${d._id}`}
                        >
                          <div className="text-black flex flex-row items-center space-x-2">
                            {d.icon && (
                              <img
                                src={d.icon}
                                alt=""
                                className="h-[30px] w-[30px] object-cover"
                              />
                            )}
                            <div>{d.title}</div>
                          </div>
                        </Link>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">
                          {formatDate(d.createdAt)}
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black">
                          {formatDate(d.updatedAt)}
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5">
                        <div className="flex items-center space-x-2">
                          <Link
                            to={`/administrator/services/${d._id}?name=${d.title}`}
                          >
                            <Button
                              className="border-secondary text-secondary"
                              icon={<PlusIcon />}
                            />
                          </Link>
                          <Button
                            className="border-primary text-primary"
                            icon={<EditIcon />}
                            onClick={() => handleEdit(d)}
                          />

                          <Button
                            danger
                            icon={<DeleteIcon />}
                            onClick={() => handleDeleteModalRender(d._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="">
                  <td className="block flex h-screen w-full items-center justify-center">
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>
      </div>
      <Modal
        title={formType === "add" ? "Add Service" : "Edit Service"}
        showModal={showModal}
        setShowModal={(value) => {
          setShowModal(value);
          // if (!value) setOffice({});
        }}
      >
        <ServiceForm
          service={service}
          formType={formType}
          handleClose={handleClose}
          onServiceAdded={() => refetch()}
        />
      </Modal>
      <Modal
        title={"Delete Service"}
        showModal={showDeleteModal}
        setShowModal={(value) => {
          setShowDeleteModal(value);
        }}
      >
        <ServiceDeleteView
          setShowDeleteModal={setShowDeleteModal}
          serviceId={selectedService}
          handleClose={() => {
            setShowDeleteModal(false);
            refetch();
          }}
        />
      </Modal>
      {/* <Modal
          title="Assigned Agents"
          showModal={agentsModal}
          setShowModal={(value) => {
            setAgentsModal(value);
            // setAgents([]);
          }}
        ></Modal> */}
    </div>
  );
};

export default Services;
