import { Link, useNavigate, useLocation } from "react-router-dom";
import fyrstup_icon from "../assets/new_icon.png";
import { useState } from "react";
import { FIRST_TIME_RESET, LOGIN_USER } from "../graphql/authQueries";
import { useMutation } from "@apollo/client";
import { useToast } from "../components/Toast/toastHook";

const FirstTimeReset = () => {
	// Use state values passed
	const location = useLocation();
	const email = location.state?.email

	const [initialValues, setInitialValues] = useState({
		onBoardingPassword: "",
		email: email,
		password: "",
	});
	
	const toast = useToast(3000);
	const navigate = useNavigate();

	const [resetPassword, { data, loading, error }] = useMutation(
		FIRST_TIME_RESET,
		{
			onCompleted(result) {
				toast("success", "Password updated! You may now safely log in");
				setTimeout(() => {
					setInitialValues({
						onBoardingPassword: "",
						email: "",
						password: "",
					});
					navigate("/");
				}, 3000);
			},
			onError(err) {
				console.error(err);
				toast(
					"error",
					"An issue occurred! Please check the credentials and try again"
				);
			},
		}
	);
	const handleSubmit = async (e) => {
		e.preventDefault();
		resetPassword({
			variables: {
				onBoardingPassword: initialValues.onBoardingPassword,
				email: initialValues.email,
				password: initialValues.password,
			},
		});
	};

	return (
		<div
			className="flex items-center"
			style={{ minHeight: "calc(100vh - 90px)" }}
		>
			<div className="rounded-sm border border-stroke bg-white shadow-lg w-full lg:w-1/2 mx-auto">
				<div className="flex flex-wrap items center">
					<div className="w-full border-stroke">
						<div className="w-full p-4 sm:p-12 xl:p-17">
							<h1 className="mb-4 text-xl font-semibold text-gray-700 text-center">
								Create your new password!
							</h1>
							<div className="mb-4 text-center text-gray-500">
								Thanks for being a FyrstUp user! Please fill out the form below
								to create your new password
							</div>

							<img src={fyrstup_icon} alt="" className="mx-auto h-40" />
							<form action="submit" onSubmit={handleSubmit}>
								<div className="mb-4">
									<label
										htmlFor="email"
										className="mb-2 block font-medium text-gray-700"
									>
										Email
									</label>
									<input
										value={initialValues.email}
										onChange={(e) =>
											setInitialValues({
												...initialValues,
												email: e.target.value,
											})
										}
										type="email"
										placeholder="user@mail.com"
										className="w-full rounded-md border border-stroke bg-gray-100 py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none"
										disabled
									/>
								</div>
								<div className="mb-4">
									<label
										htmlFor="email"
										className="mb-2 block font-medium text-gray-700"
									>
										Temporary Password
									</label>
									<input
										value={initialValues.onBoardingPassword}
										onChange={(e) =>
											setInitialValues({
												...initialValues,
												onBoardingPassword: e.target.value,
											})
										}
										type="password"
										placeholder="********"
										className="w-full rounded-md border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none"
									/>
								</div>

								<div className="mb-4">
									<label
										htmlFor="password"
										className="mb-2 block font-medium text-gray-700"
									>
										New Password
									</label>
									<input
										value={initialValues.password}
										onChange={(e) =>
											setInitialValues({
												...initialValues,
												password: e.target.value,
											})
										}
										type="password"
										placeholder="********"
										className="w-full rounded-md border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none"
									/>
								</div>

								<div className="mb-5">
									<button className="w-full rounded-lg border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90">
										Submit
									</button>
								</div>
							</form>

							<Link
								to="/"
								className="text-sm font-medium text-primary hover:underline"
							>
								Back to login?
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FirstTimeReset;
