import { gql } from "@apollo/client";


export const GET_CONTACTS_BY_SUBCOMPANY = gql`
  query GetContactsBySubCompany($subCompanyId: String!) {
  getContactsBySubCompany(subCompanyId: $subCompanyId) {
    _id
    address
    email
    name
    phone
    contacts {
      _id
      address
      createdAt
      email
      name
      phone
      position
      updatedAt
      url
    }
    createdAt
    notes
    serviceAreas
    updatedAt
    url
  }
}
`
export const BULK_CONTACTS_INSERT = gql`
mutation AddContact($BulkContactsInput: BulkContactsInput) {
  addContact(BulkContacts: $BulkContactsInput)
}
`

export const UPDATE_CONTACTS = gql`
  mutation UpdateContact($contectInput: ContactsInput) {
  updateContact(ContectInput: $contectInput) {
    _id
    address
    createdAt
    email
    name
    phone
    position
    updatedAt
    url
  }
}
` 

export const DELETE_CONTACTS = gql`
  mutation DeleteContact($contactId: String!) {
  deleteContact(contactId: $contactId) {
    _id
    address
    createdAt
    email
    name
    phone
    position
    updatedAt
    url
  }
}
`