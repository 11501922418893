import { gql } from "@apollo/client";

export const GET_SUBCOMPANIES_BY_SERVICEID = gql`
  query GetSubCompanyByServiceID($serviceId: String!) {
    getSubCompanyByServiceID(serviceId: $serviceId) {
      _id
      company
      createdAt
      icon
      subCompany {
        _id
        address
        createdAt
        name
        phone
        notes
        serviceAreas
        updatedAt
        website
      }
      title
      updatedAt
    }
  }
`;

export const ADD_SUB_COMPANY = gql`
  mutation AddSubCompany($subCompanyInput: SubCompanyInput) {
    addSubCompany(SubCompanyInput: $subCompanyInput) {
      _id
      address
      createdAt
      name
      notes
      serviceAreas
      updatedAt
      website
    }
  }
`;

export const EDIT_SUB_COMPANY = gql`
  mutation UpdateSubCompany($subCompanyInput: SubCompanyInput) {
    updateSubCompany(SubCompanyInput: $subCompanyInput) {
      _id
      address
      createdAt
      name
      phone
      notes
      serviceAreas
      updatedAt
      website
    }
  }
`;

export const DELETE_SUB_COMPANY = gql`
  mutation DeleteSubCompany($subCompanyId: ID!) {
  deleteSubCompany(subCompanyId: $subCompanyId) {
    _id
    address
    createdAt
    name
    notes
    serviceAreas
    updatedAt
    website
  }
}
`