import { useState } from "react";
import { Button } from 'antd'

const ToggleButton = ({ status, statusHandler, id }) => {
  const [ toggle, setToggle ] = useState(status)
  
  const handleToggleChange = () => {
    setToggle(!toggle)
    statusHandler(id, !toggle)
  } 
 
  return (
    <>
      <label className="flex cursor-pointer select-none items-center" onClick={handleToggleChange}>
        { toggle ? 
        <Button className="border-none text-primary">Active</Button> 
        : <Button danger className="border-none">Inactive</Button> }
      </label>
    </>
  );
};

export default ToggleButton;
