import { gql } from "@apollo/client";

export const GET_ALL_SERVICES = gql`
  query GetAllServices($companyId: ID) {
    getAllServices(companyId: $companyId) {
      _id
      title
      company
      icon
      createdAt
      updatedAt
    }
  }
`;

export const ADD_SERVICE = gql`
  mutation AddService($serviceInput: ServiceInput) {
    addService(ServiceInput: $serviceInput) {
      _id
      icon
      title
      company
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($serviceInput: ServiceInput) {
    updateService(ServiceInput: $serviceInput) {
      _id
      icon
      title
      company
      createdAt
      updatedAt
    }
  }
`

export const DELETE_SERVICE = gql`
  mutation DeleteService($serviceId: ID!) {
    deleteService(serviceId: $serviceId) {
      _id
      icon
      title
      company
      createdAt
      updatedAt
    }
  }
`
