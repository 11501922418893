import * as Yup from "yup";

export const subCompanyValidationSchema = Yup.object({
  name: Yup.string().required("Company name is required"),
  address: Yup.string().required("Company Address is required"),
  phone: Yup.string()
    .matches(/^\d{9,15}$/, "Phone number must be between 9 and 15 digits")
    .required("Phone number is required"),
  website: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/,
      "Please enter a valid URL."
    )
    .required("URL is required."),
  serviceAreas: Yup.string(),
  notes: Yup.string(),
});
