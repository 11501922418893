import * as yup from "yup";
export const validationSchema = yup.object().shape({
  name: yup.string().required("Office Name is required"),
  streetAddr: yup.string().required("Address is Required"),
  city: yup.string().required("City is Required"),
  state: yup.string().required("State is Required"),
  zip: yup.string().required("Zip Code is Required"),
  listing_extension_period: yup.string().matches(/^([1-9]|[1-8][0-9]|90)$/, 'Value must be between 1 and 90'),
  phone: yup.string().required("Phone Number is Required")  
  // .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, "Must contain 10 digits"),
  // .nonNullable()
});
