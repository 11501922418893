import * as Yup from "yup";

export const agentValidationSchema = Yup.object().shape({
  agent_info: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required("Name is required")
          .min(2, "Name must be at least 2 characters"),
        position: Yup.string()
          .required("Title is required")
          .min(2, "Title must be at least 2 characters"),
        address: Yup.string()
          .required("Office address is required")
          .min(5, "Address must be at least 5 characters"),
        email: Yup.string()
          .required("Email is required")
          .email("Invalid email address"),
        phone: Yup.string()
            .matches(/^\d{9,15}$/, "Phone number must be between 9 and 15 digits"),
        url: Yup.string()
              .matches(
                /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/,
                "Please enter a valid URL."
              )
              .required("URL is required.")
      })
    )
    .min(1, "At least one agent is required")
    .required("Agent information is required"),
});
