import { useMutation } from "@apollo/client";
import { useToast } from "../Toast/toastHook";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DELETE_SERVICE } from "../../graphql/serviceQueries";

const ServiceDeleteView = ({
  serviceId,
  handleClose,
  setShowDeleteModal,
}) => {
  const toast = useToast(3000);
  const addLoading = false;

  const handleCancelClick = () => {
    setShowDeleteModal(false);
  };

  const [DeleteService, { loading: deleteLoading }] =
    useMutation(DELETE_SERVICE);

  // Function to call the delete mutation
  const handleDeleteService = async (serviceId) => {
    try {
      const { data } = await DeleteService({
        variables: {
          serviceId
        },
      });
      toast(
        "success",
        `${data.deleteService.title} service deleted successfully.`
      );
      handleClose();
    } catch (error) {
      toast("error", error.message);
      handleClose();
    }
  };

  return (
    <form>
      <p>Are you sure you want to delete this service?</p>
      <div className="mt-6 flex space-x-2">
        <button
          type="button"
          disabled={addLoading}
          onClick={() => handleDeleteService(serviceId)}
          className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
        >
          {deleteLoading ? (
            <div className="animate-spin">
              <AiOutlineLoading3Quarters size={22} />
            </div>
          ) : (
            "Yes"
          )}
        </button>
        <button
          onClick={handleCancelClick}
          disabled={deleteLoading}
          className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ServiceDeleteView;
