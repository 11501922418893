import { useState, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import TableHeader from "../../components/offices/TableHeader";
import Modal from "../../components/modal/Modal";
import OfficeForm from "../../components/offices/OfficeForm";
import { useQuery } from "@apollo/client";
import {
  GET_COMPANY_OFFICES,
  GET_COMPANY_USERS,
} from "../../graphql/companyQueries";
import { formatPhoneNumber } from "../../utils/phoneFormat";
import { useParams } from "react-router-dom";
import PrimaryOfficeAction from "../../components/offices/PrimaryOfficeAction";
import Toast from "../../components/Toast/Toast";
import Spinner from "../../components/Spinner";

const Offices = () => {
  const [showModal, setShowModal] = useState(false);
  const [agentsModal, setAgentsModal] = useState(false);
  const [formType, setFormType] = useState("add");
  const [offices, setOffices] = useState([]);
  const [agents, setAgents] = useState([]);
  const [office, setOffice] = useState({});

  const { companyId } = useParams();

  const { loading, error, data, refetch } = useQuery(GET_COMPANY_OFFICES, {
    variables: { companyId },
    // fetchPolicy: "no-cache",
  });

  const { data: users } = useQuery(GET_COMPANY_USERS, {
    variables: { companyId },
    fetchPolicy: "no-cache",
    onError(err) {
      Toast(
        "error",
        "There was an error loading the users. If the issue persists please contact the administrators"
      );
    },
  });

  useEffect(() => {
    if (data) setOffices(data.getCompanyOffice);
  }, [data]);

  const handleEdit = (editOffice) => {
    setOffice(editOffice);
    setFormType("edit");
    setShowModal(true);
  };
  const handleModalRender = () => {
    setFormType("add");
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setOffice({});
  };

  if (loading) return <Spinner />;

  return (
    <div id="companies">
      <Breadcrumb pageName="Offices" />
      <div className="py-6">
        <Card>
          <TableHeader
            setShowModal={setShowModal}
            handleModalRender={handleModalRender}
          />
          {/* Table Headings*/}
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left grid grid-cols-6">
                <th className="col-span-1 py-4 px-4 font-medium text-black xl:pl-11">
                  Office Name
                </th>
                <th className="col-span-1  py-4 px-4 font-medium text-black ">
                  Address
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black text-center">
                  Phone
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black text-center">
                  Primary Office
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black text-center">
                  Agents
                </th>
                <th className="col-span-1 py-4 px-4 font-medium text-black text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {offices.length > 0 ? (
                offices.map((o, i) => {
                  return (
                    <tr className="grid grid-cols-6" key={i}>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4 pl-9 xl:pl-11">
                        <div className="font-medium text-black">{o.name}</div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black flex-col">
                          {o.address && <div>{`${o.address},`}</div>}
                          <div>{`${o.city}, ${o.state}, ${o.zip}`}</div>
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="font-medium text-black text-center">
                          {formatPhoneNumber(o.phone)}
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="flex font-medium text-black justify-center">
                          <PrimaryOfficeAction isDefault={o.isDefault} />
                        </div>
                      </td>
                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="flex font-medium text-black justify-center">
                          <button
                            onClick={() => {
                              setAgents(
                                users?.getCompanyUsers.filter(
                                  (user) => user.defaultOffice?.name === o.name
                                )
                              );
                              setAgentsModal(true);
                            }}
                            className="flex space-x-2 items-center bg-primary text-white rounded-full px-4 py-1 hover:opacity-90"
                          >
                            View
                          </button>
                        </div>
                      </td>

                      <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                        <div className="flex justify-center align-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() => handleEdit(o)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                              <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="flex justify-center items-center h-screen w-full">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>

        <Modal
          title={formType === 'add' ? "Add Office": "Edit Office"}
          showModal={showModal}
          setShowModal={(value) => {
            setShowModal(value);
            if (!value) setOffice({});
          }}
        >
          <OfficeForm
            office={office}
            companyId={companyId}
            formType={formType}
            handleClose={handleClose}
            allOffices={offices.map((o) => ({ id: o._id, name: o.name }))}
            refetchOffices={refetch}
          />
        </Modal>
        <Modal
          title="Assigned Agents"
          showModal={agentsModal}
          setShowModal={(value) => {
            setAgentsModal(value);
            setAgents([]);
          }}
        >
          {agents?.length ? (
            <ul>
              {agents?.map((agent, index) => (
                <li key={agent.email}>
                  {index + 1}. {agent.firstName} {agent.lastName} -{" "}
                  {agent.email}
                </li>
              ))}
            </ul>
          ) : (
            <p>No agents assigned.</p>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Offices;
