import { useMutation } from "@apollo/client";
import {
  ADD_DIRECTORY_LIST,
  EDIT_DIRECTORY_LIST,
} from "../../graphql/directoryQueries";
import { EDIT_SUB_COMPANY, ADD_SUB_COMPANY } from "../../graphql/subCompanyQueries";
import { useToast } from "../Toast/toastHook";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import Input from "../Input";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { subCompanyValidationSchema } from "./subCompany-validation";
import AutoCompleteAddressOneLine from "../AutoCompleteAddressOneLine";

const SubCompanyForm = ({
  serviceId,
  subCompany,
  directoryList,
  formType,
  handleClose,
  onDirectoryListAdded,
}) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [initialValues, setInitialValues] = useState({
    title: "",
    company: {
      id: "",
      name: "",
    },
    details: {
      address: "",
      area: "",
      name: "",
      phone: "",
      notes: "",
      url: "",
      area: "",
      // password: "",
    },
  });

  const [initialCompanyForm, setInitialCompanyForm] = useState({
    name: "",
    address: "",
    phone: "",
    website: "",
    serviceAreas: "",
    notes: "",
  });

  const toast = useToast(3000);
  const formikRef = useRef();

  // Mutation for adding a directory list
  const [
    AddSubCompany,
    { data: addData, loading: addLoading, error: addError },
  ] = useMutation(ADD_SUB_COMPANY, {
    onCompleted(result) {
      toast(
        "success",
        `Successfully saved!`
      );
      formikRef.current?.resetForm();
      handleClose();
      onDirectoryListAdded();
    },
    onError(e) {
      toast("error", `There was an issue saving the data, please try again`);
      console.error(e);
    },
  });

  // Mutation for editing a directory list
  const [
    updateSubCompany,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT_SUB_COMPANY, {
    onCompleted(result) {
      toast("success", `Successfully updated.`);
      formikRef.current?.resetForm();
      handleClose();
      onDirectoryListAdded();
    },
    onError(e) {
      toast("error", `There was an issue updating the data, please try again`);
      console.error(e);
    },
  });

  const handleFormData = () => {
    if (formType === "edit" && subCompany) {
      // setInitialValues({
      //   title: company.title || "",
      //   details: {
      //     address:
      //       company.details?.[0]?.address || company.company.address || "",
      //     area:
      //       company.details?.[0]?.area || company.company.serviceAreas || "",
      //     name: company.details?.[0]?.name || company.company.companyName || "",
      //     phone: company.details?.[0]?.phone || company.company.phone || "",
      //     notes: company.details?.[0]?.notes || company.company.notes || "",
      //     url: company.details?.[0]?.url || company.company.website || "",
      //     // password:
      //     //   company.details?.[0]?.password ||
      //     //   company.company.onBoardingPassword ||
      //     //   "",
      //   },
      // });
      setInitialCompanyForm({
        name: subCompany?.name,
        address: subCompany?.address,
        phone: subCompany?.phone,
        website: subCompany?.website,
        serviceAreas: subCompany?.serviceAreas,
        notes: subCompany?.notes
      })
    } else {
      formikRef.current?.resetForm();
    }
  };

  useEffect(() => {
    handleFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryList]);

  const handleSave = (values) => {
    const { name, address, notes, phone, serviceAreas, website, _id } = values;
    if (formType === "add") {
      AddSubCompany({
        variables: {
          subCompanyInput: {
            name,
            address,
            notes,
            phone,
            serviceAreas,
            website,
            serviceId: serviceId
          },
        },
      });
    } else {
      updateSubCompany({
        variables: {
          subCompanyInput: {
            name,
            address,
            notes,
            phone,
            serviceAreas,
            website,
            _id: subCompany?._id
          },
        },
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialCompanyForm}
      enableReinitialize={true}
      validationSchema={subCompanyValidationSchema}
      onSubmit={async (values, resetForm) => {
        handleSave(values, resetForm);
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6">
            <div className="w-full">
              <Input
                label={"Company Name"}
                name="name"
                error={errors?.name}
                value={values["name"]}
                onChange={handleChange}
                type="text"
                placeholder={"Company Name"}
              />
            </div>
            <div className="w-full ">
              <AutoCompleteAddressOneLine
                value={values["address"]}
                setFieldValue={(value) => setFieldValue("address", value)}
                errors={errors?.address}
              />
            </div>
            <div className="w-full ">
              <Input
                label={"Company Phone"}
                name="phone"
                error={errors.phone}
                value={values["phone"]}
                onChange={handleChange}
                type="text"
                placeholder={"Company Phone"}
              />
            </div>
            <div className="w-full ">
              <Input
                label={"Company Website"}
                name="website"
                error={errors?.website}
                value={values["website"]}
                onChange={handleChange}
                type="text"
                placeholder={"Company Website"}
              />
            </div>
            <div className="w-full">
              <Input
                label={"Service Areas"}
                name="serviceAreas"
                error={errors?.serviceAreas}
                value={values["serviceAreas"]}
                onChange={handleChange}
                type="text"
                placeholder={"Service Areas"}
              />
            </div>
            <div className="w-full ">
              <Input
                label={"Notes"}
                name="notes"
                error={errors?.notes}
                value={values["notes"]}
                onChange={handleChange}
                type="text"
                placeholder={"Notes"}
              />
            </div>
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              disabled={addLoading || editLoading}
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting || addLoading}
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              {addLoading || editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SubCompanyForm;
