import { useQuery } from "@apollo/client";
import useDebounce from "../hooks/useDebounce";
import {
  GET_DETAILED_ADDRESS,
  GET_SIMILAR_ADDRESSES,
} from "../graphql/listingQueries";
import { useState } from "react";
import { useToast } from "./Toast/toastHook";
import { AutoComplete as AutoCompleteAnt } from "antd";

const AutoCompleteComponent = ({ 
  values, 
  errors, 
  setFieldValue, 
  addressField = "address", 
  autofillFields = {}
}) => {
  const [options, setOptions] = useState([]);
  const [placeId, setPlaceId] = useState("");
  const debouncedValue = useDebounce(values[addressField], 200);
  const toast = useToast(300);

  const autofillListingFields = (data) => {
    Object.keys(autofillFields).forEach((field) => {
      setFieldValue(autofillFields[field], data[field] || "");
    });
  };

  useQuery(GET_DETAILED_ADDRESS, {
    variables: { placeId },
    onCompleted(result) {
      autofillListingFields(result?.getDetailedAddress || {});
    },
    onError(err) {
      console.log("error in AutoComplete/GET_DETAILED_ADDRESS", err);
    },
  });

  const { data } = useQuery(GET_SIMILAR_ADDRESSES, {
    variables: {
      address: debouncedValue,
    },
    onCompleted: (data) => {
      setOptions(
        data.autocomplete.map((value) => ({
          label: value.description,
          value: value.placeId,
        }))
      );
    },
    onError: (error) => {
      console.log("error in autocomplete", error);
      toast("error", error.message);
    },
  });

  const onSelect = (value) => {
    console.log({ value });
    setPlaceId(value);
  };

  const onSearch = (value) => {
    setFieldValue(addressField, value);
  };

  return (
    <>
      <label className="mb-2 block text-[17px] text-blue font-bold ">
        Address
      </label>
      <AutoCompleteAnt
        options={options}
        placeholder="Enter the street address"
        onSelect={onSelect}
        onSearch={onSearch}
        value={values[addressField]}
        className="w-full h-[50px]"
      />
      {errors?.[addressField] && (
        <div className="py-1 text-xs text-red">{errors?.[addressField]}</div>
      )}
    </>
  );
};

export default AutoCompleteComponent;