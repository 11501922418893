import { gql } from "@apollo/client";

export const GET_ALL_LISTINGS = gql`
  query {
    getAllListings {
      _id
      acreage
      baths
      beds
      city
      company {
        _id
        companyName
      }
      user {
        id
        firstName
        lastName
        phone
        email
        licenseNo
      }
      amenities {
        _id
      }
      office {
        _id
        name
      }
      streetAddr
      state
      sqFt
      price
      propertyType
      expiration
      lotSize
      yearBuilt
      zip
      notes
      createdAt
      coordinates
      openHouse {
        startTime
        endTime
        date
        needHost
      }
      openHouses {
        startTime
        endTime
        needHost
      }
    }
  }
`;

export const GET_ALL_OPEN_HOUSES_LISTINGS = gql`
  query {
    getAllOpenHousesListings {
      _id
      acreage
      baths
      beds
      city
      company {
        _id
        companyName
      }
      user {
        id
        firstName
        lastName
        phone
        email
        licenseNo
      }
      amenities {
        _id
      }
      office {
        _id
        name
      }
      streetAddr
      state
      sqFt
      price
      propertyType
      expiration
      lotSize
      yearBuilt
      zip
      notes
      createdAt
      coordinates
      openHouse {
        startTime
        endTime
        date
        needHost
      }
      openHouses {
        startTime
        endTime
        needHost
      }
    }
  }
`;

export const GET_COMPANY_LISTINGS = gql`
  query ($companyId: String!) {
    getCompanyListings(companyId: $companyId) {
      _id
      acreage
      baths
      beds
      city
      company {
        _id
        companyName
      }
      user {
        id
        firstName
        lastName
        phone
        email
        licenseNo
      }
      amenities {
        _id
      }
      office {
        _id
        name
      }
      streetAddr
      state
      sqFt
      price
      propertyType
      expiration
      lotSize
      yearBuilt
      zip
      notes
      createdAt
      coordinates
      openHouse {
        startTime
        endTime
        date
        needHost
      }
      openHouses {
        startTime
        endTime
        needHost
      }
      links
    }
  }
`;

export const GET_LISTING_BY_ID = gql`
  query ($listingId: String) {
    getListingById(listingId: $listingId) {
      _id
      expiration
      streetAddr
      city
      state
      zip
      beds
      baths
      yearBuilt
      price
      sqFt
      lotSize
      acreage
      buyer_commission
      propertyType
      company {
        _id
      }
      user {
        id
      }
      notes
      createdAt
      deletedAt
      office {
        _id
      }
      amenities {
        _id
      }
      coordinates
      imageURLs
      openHouse {
        startTime
        endTime
        date
        needHost
      }
      openHouses {
        startTime
        endTime
        needHost
      }
      links
    }
  }
`;

export const ADD_LISTING = gql`
  mutation ($listingInput: ListingInput) {
    addListing(listingInput: $listingInput) {
      _id
      city
      streetAddr
      state
      zip
      beds
      baths
      sqFt
      lotSize
      acreage
      price
      createdAt
      expiration
      notes
      yearBuilt
      propertyType
      openHouse {
        startTime
        endTime
        date
        needHost
      }
    }
  }
`;

export const UPDATE_LISTING = gql`
  mutation ($listingInput: ListingInput) {
    updateListing(listingInput: $listingInput) {
      _id
      city
      streetAddr
      state
      zip
      beds
      baths
      sqFt
      lotSize
      acreage
      price
      propertyType
      createdAt
      expiration
      notes
      yearBuilt
      openHouse {
        startTime
        endTime
        date
        needHost
      }
    }
  }
`;

export const DELETE_LISTING = gql`
  mutation ($listingId: String) {
    deleteListing(listingId: $listingId) {
      _id
    }
  }
`;

export const GET_SIMILAR_ADDRESSES = gql`
  query ($address: String!) {
    autocomplete(address: $address) {
      description
      placeId
    }
  }
`;

export const GET_DETAILED_ADDRESS = gql`
  query ($placeId: String!) {
    getDetailedAddress(placeId: $placeId) {
      city
      shortAddress
      state
      zipCode
    }
  }
`;

export const BULK_LISTING_UPLOAD = gql`
  mutation ($listingBulk: [ListingBulk]) {
    bulkListingUpload(listingBulk: $listingBulk) {
      totalSaved
      notSaved {
        StreetAddr
        City
      }
    }
  }
`;
